<script>
export default {
	async created() {
		await this.$auth.waitReady();
		this.$auth.logout();
	},
}
</script>

<template>
	<div/>
</template>
