<template>
  <div class="page-help">
    <el-card ref="card">
      <div slot="header">
        <span
          ><h5>{{ title }}</h5></span
        >
        <el-link style="float: right" :underline="false" href="#/help"
          ><i class="bx bx-arrow-back"></i> Back</el-link
        >
      </div>
      <div class="card-iframe" ref="card">
        <iframe
          width="1170px"
          height="100%"
          scrolling="auto"
          :src="url"
        ></iframe>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "HelpDocs",
  props: {
    title: String,
    url: String
  }
};
</script>

<style>
.page-help {
  height: 100vh;
  /*overflow-y: auto;*/
}
.el-card {
  width: 1220px;
  height: 780px;
}
.card-iframe {
  height: 600px;
  overflow-y: auto;
}
</style>
