import { DeepstreamClient } from '@deepstream/client';
export default {
	data() {
		return {
			// Stores deepstream client
			client: new DeepstreamClient('wss://data.sr-accelerator.com:6020'),
			// Stores the deepstream record
			record: null,
			// Array of project ID's
			projects: [],
			// Stores object of projects records keyed by ID
			projectRecord: {},
			// Stores object of projects metadata keyed by ID
			projectMetadata: {},
			// Stores selected project ID
			selectedProject: null,
			// New project name (when creating new project)
			newName: ""
		}
	},
	async created() {
		this.client.login();
		await this.$auth.waitReady();
		const userId = this.$auth.user.sub;
		// Get record based on user
		this.record = this.client.record.getRecord(`user/${userId}`);
		// Subscribe to projects
		this.record.subscribe('projects', projects => {
			if (Array.isArray(projects)) {
				this.projects = projects;
				projects.forEach(id => {
					this.$set(this.projectRecord, id, this.client.record.getRecord(`project/${id}`));
					this.projectRecord[id].subscribe('metadata', metadata => {
						this.$set(this.projectMetadata, id, metadata);
					});
				})
			}
		})
		// Subscribe to selected project
		this.record.subscribe('selectedProject', selectedProject => {
			this.selectedProject = selectedProject
		})
	},
	watch: {
		selectedProject: function(newVal) {
			this.record.set('selectedProject', newVal);
		}
	},
	methods: {
		addProject({ name, owner, dateCreated }) {
			console.log("Adding project:", name)
			// ID for new project
			const id = this.client.getUid();
			// ID for methods section (in metadata)
			const methods = this.client.getUid();
			// Push new id to projects
			this.projects.push(id);
			this.record.set('projects', this.projects);
			// Set project metadata locally
			this.$set(this.projectMetadata, id, {
				name,
				owner,
				dateCreated,
				dateModified: dateCreated,
				methods
			});
			// Add new project to records and set metadata remotely
			this.$set(this.projectRecord, id, this.client.record.getRecord(`project/${id}`));
			this.projectRecord[id].set('metadata', this.projectMetadata[id]);
			// Subscribe to metadata
			this.projectRecord[id].subscribe('metadata', metadata => {
				this.$set(this.projectMetadata, id, metadata);
			});
		},
		updateProjectName(id, name) {
			console.log("Updating project:", id, name);
			// Update name and modified
			this.projectMetadata[id].name = name;
			this.projectMetadata[id].dateModified = Date.now();
			// Set metadata remotely
			this.projectRecord[id].set('metadata', this.projectMetadata[id]);
		}
	}
}