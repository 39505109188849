<template>
	<table class="table table-hover">
		<thead>
			<tr>
				<th scope="col">Active</th>
				<th scope="col">Project Name</th>
				<th scope="col">Owner</th>
				<th scope="col">Created</th>
				<th scope="col">Modified</th>
				<th scope="col">ID</th>
				<th scope="col">Edit</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="projectId of Object.keys(projectMetadata)"
				@click="selectedProject = projectId"
				:class="{ 'table-primary': projectId == selectedProject }"
				:key="projectId"
			>
				<!-- Active -->
				<td><i v-if="projectId == selectedProject" class="bx bx-check" /><i v-else class="bx bx-x" /></td>
				<!-- Name / Edit name -->
				<th scope="row">
					<input v-if="projectId == editId" v-model="projectMetadata[projectId].name" />
					<span v-else>{{projectMetadata[projectId].name}}</span>
				</th>
				<!-- Owner -->
				<td>{{projectMetadata[projectId].owner}}</td>
				<!-- Created -->
				<td>
					{{new Date(projectMetadata[projectId].dateCreated).toDateString()}}
					{{new Date(projectMetadata[projectId].dateCreated).toLocaleTimeString()}}
				</td>
				<!-- Modified -->
				<td>
					{{new Date(projectMetadata[projectId].dateModified).toDateString()}}
					{{new Date(projectMetadata[projectId].dateModified).toLocaleTimeString()}}
				</td>
				<!-- ID -->
				<td>{{projectId}}</td>
				<!-- Edit button -->
				<td>
					<button
						v-if="projectId == editId"
						@click="save(projectId, projectMetadata[projectId].name)"
						class="btn btn-success btn-sm"
					>
						Save
					</button>
					<button v-else @click="edit(projectId)" class="btn btn-primary btn-sm">Edit</button>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import DeepstreamMixin from "../mixins/DeepstreamMixin.js";

export default {
	mixins: [
		DeepstreamMixin
	],
	data() {
		return {
			editId: null
		}
	},
	methods: {
		edit(projectId) {
			this.editId = projectId;
		},
		save(projectId, name) {
			this.editId = null;
			this.updateProjectName(projectId, name);
		}
	}
}
</script>
