<template>
	<div>
		<AppLoadingScreen v-if="!isLoaded" />
		<iframe @load="isLoaded = true" v-show="isLoaded" class="appFrame" allow="clipboard-write" :src="fullUrl"></iframe>
	</div>
</template>

<script>
import AppLoadingScreen from "./AppLoadingScreen.vue";
import DeepstreamMixin from "../mixins/DeepstreamMixin.js";

export default {
	name: "ToolWrapper",
	props: {
		// Base url
		url: String,
		// Project id passed by url, maybe add in future once deepstream auth complete
		// May cause issues when users navigate by recent urls, or make up url
		// urlProjectId: String,
		// Boolean if project id should be added to end of url as query
		addProjectId: Boolean
	},
	mixins: [
		DeepstreamMixin
	],
	components: {
		AppLoadingScreen
	},
	computed: {
		fullUrl() {
			var fullUrl = this.url;
			if (this.addProjectId && this.selectedProject) {
				fullUrl += `#/?project-id=${this.selectedProject}`;
			}
			console.log(fullUrl);
			return fullUrl;
		}
	},
	data() {
		return {
			isLoaded: false,
		}
	},
	watch: {
		$route() {
			this.isLoaded = false;
		}
	}
}
</script>

<style>
.appFrame {
	height: calc(100vh);
	width: 100%;
	display:block;
}
.loader-59 {
	width: 48px;
	height: 48px;
	display: inline-block;
	position: relative;
	transform: rotate(45deg);
}
 .loader-59::before {
	content: '';
	width: 24px;
	height: 24px;
	position: absolute;
	left: 0;
	top: -24px;
	animation: animloader59 4s ease infinite;
}
 .loader-59::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 24px;
	height: 24px;
	background: rgba(255, 255, 255, 0.85);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	animation: animloader56 2s ease infinite;
}
 @keyframes animloader56 {
	0% {
		transform: translate(0, 0) rotateX(0) rotateY(0);
	}
	25% {
		transform: translate(100%, 0) rotateX(0) rotateY(180deg);
	}
	50% {
		transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
	}
	75% {
		transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
	}
	100% {
		transform: translate(0, 0) rotateX(0) rotateY(360deg);
	}
}
 @keyframes animloader59 {
	0% {
		box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
	}
	12% {
		box-shadow: 0 24px rgba(255, 255, 255, 1), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
	}
	25% {
		box-shadow: 0 24px rgba(255, 255, 255, 1), 24px 24px rgba(255, 255, 255, 1), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
	}
	37% {
		box-shadow: 0 24px rgba(255, 255, 255, 1), 24px 24px rgba(255, 255, 255, 1), 24px 48px rgba(255, 255, 255, 1), 0px 48px rgba(255, 255, 255, 0);
	}
	50% {
		box-shadow: 0 24px rgba(255, 255, 255, 1), 24px 24px rgba(255, 255, 255, 1), 24px 48px rgba(255, 255, 255, 1), 0px 48px rgba(255, 255, 255, 1);
	}
	62% {
		box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 1), 24px 48px rgba(255, 255, 255, 1), 0px 48px rgba(255, 255, 255, 1);
	}
	75% {
		box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 1), 0px 48px rgba(255, 255, 255, 1);
	}
	87% {
		box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 1);
	}
	100% {
		box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
	}
}
</style>