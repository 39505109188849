import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

// Router
import VueRouter from "vue-router";
Vue.use(VueRouter);

import ToolWrapper from "./components/ToolWrapper.vue";
import AuthSignIn from "./components/AuthSignIn.vue";
import AuthSignOut from "./components/AuthSignOut.vue";
import AuthProfile from "./components/AuthProfile.vue";
import ProjectManage from "./components/ProjectManage.vue";
//import Help from "./components/Help.vue";
import HelpList from "./components/HelpList.vue";
import HelpDocs from "./components/HelpDocs.vue";
import ContactUs from "./components/ContactUs.vue";

// Auth0
import { Auth0Plugin } from "./services/auth";
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  onRedirectCallback(appState) {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

//Element UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

//VueBootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const routes = [
  {
    path: "/login",
    component: AuthSignIn
  },
  {
    path: "/logout",
    component: AuthSignOut
  },
  {
    path: "/profile",
    component: AuthProfile
  },
  {
    path: "/projects",
    component: ProjectManage
  },
  {
    path: "/methodswizard",
    component: ToolWrapper,
    props: route => ({
      //url: "https://methodswizard-beta.netlify.app/",
      url: "https://methodswizard.sr-accelerator.com/",
      urlProjectId: route.query["project-id"],
      addProjectId: true
    })
  },
  {
    path: "/wordfreq",
    component: ToolWrapper,
    props: {
      url: "https://wordfreq.sr-accelerator.com/"
    }
  },
  {
    path: "/searchrefinery",
    component: ToolWrapper,
    props: {
      url: "https://searchrefinery.sr-accelerator.com/"
    }
  },
  {
    path: "/polyglot",
    component: ToolWrapper,
    props: {
      url: "https://polyglot.sr-accelerator.com/"
    }
  },
  {
    path: "/deduplicator",
    component: ToolWrapper,
    props: {
      url: "https://dedupe.sr-accelerator.com/"
    }
  },
  {
    path: "/screenatron",
    component: ToolWrapper,
    props: {
      url: "https://screenatron.sr-accelerator.com/"
    }
  },
  {
    path: "/disputatron",
    component: ToolWrapper,
    props: {
      url: "https://disputatron.sr-accelerator.com/"
    }
  },
  {
    path: "/spidercite",
    component: ToolWrapper,
    props: {
      url: "https://spidercite.sr-accelerator.com/"
    }
  },
  {
    path: "/help",
    component: HelpList,
    props: {
      url: "/help"
    }
  },
  // helpList
  {
    path: "/help/overview",
    component: HelpDocs,
    props: {
      title: "SRA Overview",
      url: "https://docs.google.com/document/d/1ri1GimPmkCcVy4bI5ot15FwEGSLOwtrn0P_tDpQ6WsY/pub?embedded=true"
    }
  },
  {
    path: "/help/libraries",
    component: HelpDocs,
    props: {
      title: "Importing / Exporting Libraries",
      url: "https://docs.google.com/document/d/e/2PACX-1vRbqjv2_BB-IGVCV5lH8Po97muvY7LSZN9qiK1ti8Qm6n5C1Yc2GoGoOnzmD8dryf_pVQwrDqXdoXIZ/pub?embedded=true"
    }
  },
  {
    path: "/help/wordfreq",
    component: HelpDocs,
    props: {
      title: "Word Frequency Analyser",
      url: "https://docs.google.com/document/d/e/2PACX-1vSMaEiS-SvH-8ymVp_oKVhbyTUJKpHKayN4hgFAlyBHJFrGcby_KOHS-yAhD87XB2yODkdnOD0wEKEG/pub?embedded=true"
    }
  },
  {
    path: "/help/searchRefinery",
    component: HelpDocs,
    props: {
      title: "SearchRefinery",
      url: "https://docs.google.com/document/d/e/2PACX-1vSoxP2JYqUtCr6gLZTbSx8-vJ14X1vM6pL6WY4Whayyc8Yeyh4zCOlCJOSUf2Nhaq_C_-ZMmDHcUZ1d/pub?embedded=true"
    }
  },
  {
    path: "/help/polyglot",
    component: HelpDocs,
    props: {
      title: "Polyglot Search Translator",
      url: "https://docs.google.com/document/d/1zL2_bIyZyhikrir-veUc2M59e7_c5iAmsHIu6JBxyB4/pub?embedded=true"
    }
  },
  {
    path: "/help/deduplicator",
    component: HelpDocs,
    props: {
      title: "Deduplicator",
      url: "https://docs.google.com/document/d/e/2PACX-1vRZshMTQ_61kWRKQdwIgb1vUOH0UK0fdw1aiRMOoSp7V_EEkzN-RAsSbcKee-003vzdK72LqdUijYCC/pub?embedded=true"
    }
  },
  {
    path: "/help/screenatron",
    component: HelpDocs,
    props: {
      title: "Screenatron",
      url: "https://docs.google.com/document/d/e/2PACX-1vQbXMaBlal2LXZoY1Km6Gq3ZmxQkGpNbNyuQM1MpkqQ9S0T7Zv--UW7h96ug4L9FUWrcKadZqVoFKa5/pub?embedded=true"
    }
  },
  {
    path: "/help/disputatron",
    component: HelpDocs,
    props: {
      title: "Disputatron",
      url: "https://docs.google.com/document/d/e/2PACX-1vR__jD5ZsH6dqQMqgFoppbrWglXedHE_3L_t7De2JeBGN-vlTCRnq-QgY5o8UAJWx6fFfgnODo0KMBa/pub?embedded=true"
    }
  },
  {
    path: "/help/spidercite",
    component: HelpDocs,
    props: {
      title: "SpiderCite",
      url: "https://docs.google.com/document/d/e/2PACX-1vQvLTMeQl3N0FLDO2GxzqAtnq1X5znakUXSEL2hUVNRjiEd3dfvDaNqDmZMl0B42SqaNNz8l7lSL0R_/pub?embedded=true"
    }
  },
  {
    path: "/help/tools",
    component: HelpDocs,
    props: {
      title: "Recommended Tools",
      url: "https://docs.google.com/document/d/1yO_O1kpwYmkVWcWXeTjbd2jK53nTItxNbAZx07wgjKg/pub?embedded=true"
    }
  },
  {
    path: "/help/citing",
    component: HelpDocs,
    props: {
      title: "Citing us",
      url: "https://docs.google.com/document/d/e/2PACX-1vSXq5kubanl-APyOARTgcPWsOyYCW54rSa-tXbJEO25942h_MXHEX3LABOMwkBYxUFUgyJ9_evOaOoQ/pub?embedded=true"
    }
  },
  {
    path: "/help/licenses",
    component: HelpDocs,
    props: {
      title: "Licenses",
      url: "https://docs.google.com/document/d/e/2PACX-1vTZf8JT-vvPJf6OwYTzmFhW9crSmRkpdqcwy8PpuumBTO4r7l9ocgvOPUPx9Njhg-zHiOSfajZdaEJV/pub?embedded=true"
    }
  },
  {
    path: "/help/aboutUs",
    component: HelpDocs,
    props: {
      title: "About Us",
      url: "https://docs.google.com/document/d/e/2PACX-1vRA802xdOej_gfwV2SFcG29xjD1-s9HB_2wMxFl1GKx8bnNb9TDso2xnEYbZQjgyzDbzyA7RWqQWiR1/pub?embedded=true"
    }
  },
  {
    path: "/help/contactUs",
    component: ContactUs
  }
];

const router = new VueRouter({
  routes
});

new Vue({
  render: h => h(App),
  router
}).$mount("#app");
