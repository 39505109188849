<template>
	<div>
		<!-- Main Page -->
		<div class="bg-login section-authentication-signin">
			<div class="page-content">
				<div class="container">
					<div class="main-body">
						<div class="row">
							<div class="col-12">
								<div class="card p-2">
									<div class="card-body">
										<h1>Projects</h1>
										<ProjectManageTable />
										<button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#nameModal">
											<i class="bx bx-plus" />Create New Project
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Main Page -->
	</div>
</template>

<script>
import ProjectManageTable from './ProjectManageTable.vue';

export default {
	components: {
		ProjectManageTable
	}
}
</script>