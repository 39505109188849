<template>
	<!-- New Project Modal -->
	<div class="modal fade" id="nameModal" tabindex="-1" aria-labelledby="nameModalTitle" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="nameModalTitle">New project</h5>
					<button @click="newName = ''" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					Enter project name below:
					<input v-model="newName" type="text" class="form-control" placeholder="Project name" aria-label="Project name" />
				</div>
				<div class="modal-footer">
					<button @click="newName = ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button @click="createProject" type="button" class="btn btn-success" data-bs-dismiss="modal">Create Project</button>
				</div>
			</div>
		</div>
	</div>
	<!-- End New Project Modal -->
</template>

<script>
import DeepstreamMixin from "../mixins/DeepstreamMixin"
export default {
	mixins: [ DeepstreamMixin ],
	methods: {
		createProject() {
			this.addProject({
				name: this.newName,
				owner: this.$auth.user.name,
				dateCreated: Date.now()
			})
			this.newName = "";
		}
	}
}
</script>