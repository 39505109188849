<template>
	<div/>
</template>

<script>
export default {
	async created() {
		await this.$auth.waitReady();
		this.$auth.loginWithRedirect();
	},
}
</script>
