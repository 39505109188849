/**
* Auth0 auth guard component
* @see https://github.com/auth0-samples/auth0-vue-samples/blob/master/01-Login/src/auth/authWrapper.js
*/

import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () => window.location.origin;

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	redirectUri = window.location.origin,
	...options
}) => {
	if (instance) return instance;

	instance = new Vue({
		data() {
			return {
				loading: true,
				isAuthenticated: false,
				user: {},
				auth0Client: null,
				popupOpen: false,
				error: null
			};
		},
		methods: {
			async loginWithPopup(options, config) {
				this.popupOpen = true;

				try {
					await this.auth0Client.loginWithPopup(options, config);
					this.user = await this.auth0Client.getUser();
					this.isAuthenticated = await this.auth0Client.isAuthenticated();
					this.error = null;
				} catch (e) {
					console.error(e);
					this.error = e;
				} finally {
					this.popupOpen = false;
				}
			},
			async handleRedirectCallback() {
				this.loading = true;
				try {
					await this.auth0Client.handleRedirectCallback();
					this.user = await this.auth0Client.getUser();
					this.isAuthenticated = true;
					this.error = null;
				} catch (e) {
					this.error = e;
				} finally {
					this.loading = false;
				}
			},
			loginWithRedirect(o) {
				return this.auth0Client.loginWithRedirect(o);
			},
			getIdTokenClaims(o) {
				return this.auth0Client.getIdTokenClaims(o);
			},
			getTokenSilently(o) {
				return this.auth0Client.getTokenSilently(o);
			},
			getTokenWithPopup(o) {
				return this.auth0Client.getTokenWithPopup(o);
			},
			logout(o) {
				return this.auth0Client.logout(o);
			},
			waitReady() {
				return new Promise(resolve => {
					var tryClient = ()=> {
						if (this.auth0Client) return resolve();
						setTimeout(tryClient, 100); // Try again in 100ms
					};
					tryClient();
				});
			},
		},
		async created() {
			try {
				this.auth0Client = await createAuth0Client({
					...options,
					client_id: options.clientId,
					redirect_uri: redirectUri
				});

				if (
					window.location.search.includes("code=") &&
					window.location.search.includes("state=")
				) {
					const { appState } = await this.auth0Client.handleRedirectCallback();
					this.error = null;
					onRedirectCallback(appState);
					window.location = '/';
				}
			} catch (e) {
				this.error = e;
			} finally {
				this.isAuthenticated = await this.auth0Client.isAuthenticated();
				this.user = await this.auth0Client.getUser();
				this.loading = false;
			}
		}
	});

	return instance;
};

export const Auth0Plugin = {
	install(Vue, options) {
		Vue.prototype.$auth = useAuth0(options);
	}
};
