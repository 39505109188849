<template>
  <div class="page-help">
    <b-card class="container-help" header="Help Topics">
      <b-list-group v-for="topic in topics" :key="(id = topic.id)">
        <b-list-group-item v-if="topic.show == true" a :href="getHelpUrl(id)">{{
          topic.title
        }}</b-list-group-item>
      </b-list-group>
      <b-list-group
        ><b-button block variant="outline-secondary" href="#/help/contactUs"
          ><i class="bx bx-mail-send"></i> Contact Us</b-button
        ></b-list-group
      >
    </b-card>
  </div>
</template>

<script>
import { helplist } from "@/list/helplist";

export default {
  data() {
    return {
      topics: helplist()
    };
  },
  methods: {
    getHelpUrl(id) {
      return "#/help/" + id;
    }
  }
};
</script>

<style>
.container-help {
  height: 600px;
  width: 800px;
}
.page-help {
  height: 100vh;
  overflow-y: auto;
  background-color: rgb(253, 253, 253);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-help {
  min-height: 100vh;
}
</style>
