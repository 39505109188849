<template>
	<div>
		<!-- PROFILE PAGE -->
		<div v-if="user" class="bg-login section-authentication-signin">
			<div class="page-content">
				<!-- PAGE CONTENT -->
				<div class="container">
					<div class="main-body">
						<div class="row">
							<div class="col-lg-4"></div>
							<div class="col-lg-4">
								<div class="card p-2">
									<div class="card-body">
										<div class="d-flex flex-column align-items-center text-center">
											<img :src="user.picture" alt="Profile" class="rounded-circle p-1 bg-primary" width="110">
											<div class="mt-3">
												<h4>{{ user.nickname }}</h4>
												<p class="text-secondary mb-1">{{ user.email }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<div class="card">
									<div class="card-header">
										<h4>Profile Information</h4>
									</div>
									<div class="card-body">
										<div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Name</h6>
											</div>
											<div class="col-sm-9 text-secondary">
												<input type="text" class="form-control" v-model="user.name" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Email</h6>
											</div>
											<div class="col-sm-9 text-secondary">
												<input type="text" class="form-control" v-model="user.email" />
											</div>
										</div>
										<div class="row mb-3">
											<div class="col-sm-2">
												<h6 class="mb-0">Nickname</h6>
											</div>
											<div class="col-sm-9 text-secondary">
												<input type="text" class="form-control" v-model="user.nickname" />
											</div>
										</div>
										<div class="row">
											<div class="col-sm-2"></div>
											<div class="col-sm-9 text-secondary">
												<input type="button" class="btn btn-primary px-4" value="Save Changes" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AppLoadingScreen v-else />
	</div>
</template>

<script>
import AppLoadingScreen from './AppLoadingScreen.vue';
export default {
	components: { AppLoadingScreen },
	data() {
		return {
			user: null
		}
	},
	async created() {
		await this.$auth.waitReady();
		this.user = this.$auth.user;
		console.log('$auth', this.$auth);
	},
}
</script>
