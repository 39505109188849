export function helplist() {
  var topics = [
    {
      id: "overview",
      title: "SRA Overview",
      show: true
    },
    {
      id: "libraries",
      title: "Importing / Exporting Libraries",
      show: true
    },
    {
      id: "wordfreq",
      title: "Word Frequency Analyser",
      show: true
    },
    {
      id: "searchRefinery",
      title: "SearchRefinery",
      show: true
    },
    {
      id: "polyglot",
      title: "Polyglot Search Translator",
      show: true
    },
    {
      id: "deduplicator",
      title: "Deduplicator",
      show: true
    },
    {
      id: "screenatron",
      title: "Screenatron",
      show: true
    },
    {
      id: "disputatron",
      title: "Disputatron",
      show: true
    },
    {
      id: "spidercite",
      title: "SpiderCite",
      show: true
    },

    // Accessible from top level menu - dont show the following in help topics list
    {
      id: "tools",
      title: "Recommended Tools",
      show: false
    },
    {
      id: "citing",
      title: "Citing us",
      show: true
    },
    {
      id: "aboutus",
      title: "About Us",
      show: true
    },
    {
      id: "contactus",
      title: "Contact Us",
      show: false
    },
    {
      id: "licenses",
      title: "Licenses",
      show: true
    }
  ];
  return topics;
}
