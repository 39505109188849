<template>
  <div>
    <el-card class="box">
      <b-alert show variant="info">
        <p>Drop us an email with the form below.</p>
        <p>
          Alternatively you can email us at
          <a href="mailto:theteam@sr-accelerator.com"
            >theteam@sr-accelerator.com</a
          >
        </p>
        <el-link :underline="false" href="#/help"
          ><i class="bx bx-arrow-back"></i> Back</el-link
        >
      </b-alert>
      <el-card class="mail-card" shadow="hover">
        <el-input placeholder="John Smith" v-model="name">
          <template slot="prepend">
            <li class="el-icon-user"></li>
            Your name:
          </template>
        </el-input>
        <el-input
          placeholder="The subject you want to contact us about"
          v-model="subject"
        >
          <template slot="prepend">Subject: </template>
        </el-input>
        <el-input placeholder="someone@somewhere.com" v-model="email">
          <template slot="prepend">
            <li class="el-icon-paperclip"></li>
            Email To:
          </template>
        </el-input>
        <div style="margin: 20px 0"></div>
        <el-input
          type="textarea"
          v-model="text"
          maxlength="1000"
          rows="15"
          show-word-limit
        >
        </el-input>
        <div style="margin: 20px 0"></div>
        <div class="el-button-centered">
          <el-button type="primary" plain
            ><i class="el-icon-s-promotion"></i> Send contact request</el-button
          >
        </div>
      </el-card>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      subject: "",
      email: "",
      text: ""
    };
  }
};
</script>
<style>
.box {
  width: 100%;
  height: 100vh;
}

.mail-card {
  width: 100%;
  height: 600px;
}

.el-button-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
