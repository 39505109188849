<template>
  <div class="wrapper background-gradient-light toggled">
    <!-- NAVIGATION -->
    <div class="sidebar-wrapper background-gradient" data-simplebar="true">
      <div class="sidebar-header">
        <div>
          <img
            src="/assets/images/logo-icon.png"
            class="logo-icon"
            alt="logo icon"
          />
        </div>
        <div>
          <h4 class="logo-text">SRA</h4>
        </div>
        <div class="toggle-icon ms-auto">
          <i class="bx bx-fullscreen"></i>
        </div>
      </div>
      <ul class="metismenu" id="menu">
        <li v-if="$auth.user">
          <router-link to="/projects">
            <div class="parent-icon"><i class="bx bx-layer-plus"></i></div>
            <div class="menu-title">Projects</div>
          </router-link>
        </li>
        <li v-if="$auth.user">
          <router-link to="/profile">
            <div class="parent-icon">
              <img
                class="profile-pic"
                height="25px"
                :src="$auth.user.picture"
              />
            </div>
            <div class="menu-title">Profile</div>
          </router-link>
        </li>
        <li v-else>
          <router-link to="/login">
            <div class="parent-icon"><i class="lni lni-user"></i></div>
            <div class="menu-title">Login/Signup</div>
          </router-link>
        </li>
        <li>
          <router-link to="/methodswizard">
            <div class="parent-icon"><i class="lni lni-book"></i></div>
            <div class="menu-title">MethodsWizard</div>
          </router-link>
        </li>
        <li>
          <router-link to="/wordfreq">
            <div class="parent-icon"><i class="lni lni-keyboard"></i></div>
            <div class="menu-title">WordFreq</div>
          </router-link>
        </li>
        <li>
          <router-link to="/searchrefinery">
            <div class="parent-icon">
              <i class="lni lni-keyword-research"></i>
            </div>
            <div class="menu-title">SearchRefinery</div>
          </router-link>
        </li>
        <li>
          <router-link to="/polyglot">
            <div class="parent-icon"><i class="bx bx-search-alt"></i></div>
            <div class="menu-title">Polyglot</div>
          </router-link>
        </li>
        <li>
          <router-link to="/deduplicator">
            <div class="parent-icon"><i class="bx bx-copy"></i></div>
            <div class="menu-title">Deduplicator</div>
          </router-link>
        </li>
        <li>
          <router-link to="/screenatron">
            <div class="parent-icon"><i class="lni lni-share"></i></div>
            <div class="menu-title">Screenatron</div>
          </router-link>
        </li>
        <li>
          <router-link to="/disputatron">
            <div class="parent-icon"><i class="lni lni-users"></i></div>
            <div class="menu-title">Disputatron</div>
          </router-link>
        </li>
        <li>
          <router-link to="/spidercite">
            <div class="parent-icon"><i class="lni lni-cloud-network"></i></div>
            <div class="menu-title">SpiderCite</div>
          </router-link>
        </li>
        <li>
          <router-link to="/help">
            <div class="parent-icon"><i class="bx bx-folder"></i></div>
            <div class="menu-title">Help</div>
          </router-link>
          <!-- <el-menu
            text-color="#F5F5F5"
            background-color="rgb(68, 88, 134)"
            router
          >
            <el-menu-item
              v-for="topic in showHelpList"
              :key="topic.id"
              :index="gdocPath(topic)"
              style="height: 50px; font-size: 13px"
            >
              <span slot="title">{{ topic.title }}</span>
            </el-menu-item>
          </el-menu>-->
        </li>
        <!-- <li>
          <router-link to="/help/tools">
            <div class="parent-icon"><i class="bx bx-filter-alt"></i></div>
            <div class="menu-title">Recommended Tools</div>
          </router-link>
        </li>
        <li>
          <router-link to="/help/aboutUs">
            <div class="parent-icon"><i class="bx bx-envelope"></i></div>
            <div class="menu-title">About Us</div>
          </router-link>
        </li>
        <li>
          <router-link to="/help/citing">
            <div class="parent-icon"><i class="bx bx-edit-alt"></i></div>
            <div class="menu-title">Citing Us</div>
          </router-link>
        </li>
        <li>
          <router-link to="/help/contactUs">
            <div class="parent-icon"><i class="bx bx-mail-send"></i></div>
            <div class="menu-title">Contact Us</div>
          </router-link>
        </li>-->

        <li v-if="$auth.user">
          <router-link to="/logout">
            <div class="parent-icon"><i class="bx bx-log-out"></i></div>
            <div class="menu-title">Logout</div>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- PAGE CONTENT -->
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <router-view />
      </div>
    </div>
    <ProjectNewModal />
  </div>
</template>

<script>
import ProjectNewModal from "./components/ProjectNewModal";
import DeepstreamMixin from "./mixins/DeepstreamMixin";
import { helplist } from "./list/helplist";

export default {
  name: "App",
  mixins: [DeepstreamMixin],
  components: {
    ProjectNewModal
  },
  computed: {
    showHelpList() {
      let showHelp = [];
      this.topics.forEach(key => {
        if (key.show == true) {
          showHelp.push(key);
        }
      });
      return showHelp;
    }
  },
  data() {
    return {
      topics: helplist()
    };
  },
  methods: {
    gdocPath(topic) {
      return "/help/" + topic.id;
    }
  }
};
</script>

<style>
.profile-pic {
  border-radius: 50%;
}
.background-gradient {
  background: rgb(68, 88, 134) !important;
  background: radial-gradient(
    circle,
    rgba(68, 88, 134, 1) 0%,
    rgb(92, 124, 192) 100%
  ) !important;
}
.background-gradient-light {
  background: rgb(38, 49, 74);
  background: radial-gradient(
    0deg,
    rgba(38, 49, 74, 1) 0%,
    rgba(23, 31, 48, 1) 100%
  );
}

.page-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}

.sidebar-wrapper a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
